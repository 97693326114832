export interface HeaderBannerData {
  id: string;
  text: string;
  isActive: boolean;
  icon?: string | null;
  variant?: string | null;
  button?: {
    text?: string | null;
    url?: string | null;
  } | null;
}

export const HEADER_BANNER_VARIANT = {
  GRADIENT: 'GRADIENT',
  ACCENT: 'ACCENT',
} as const;

export type HeaderBannerVariantName = keyof typeof HEADER_BANNER_VARIANT;

export type HeaderBannerVariant = (typeof HEADER_BANNER_VARIANT)[HeaderBannerVariantName];
