import React, { useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import ImageProxy from '@next-image-proxy';

import {
  HEADER_BANNER_VARIANT,
  HeaderBannerData,
  HeaderBannerVariant,
} from '@shared/api/types/headerBanner';

import { ExternalLink } from '@ui-uikit/lib/components/common/ExternalLink';
import { ArrowRight } from '@ui-uikit/lib/components/icons/mono';

interface HeaderBannerProps {
  className?: string;
  headerBanner?: HeaderBannerData;
}

const HeaderBanner = ({ className, headerBanner }: HeaderBannerProps) => {
  const [isActive, setIsActive] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const buttonStyleClassName =
    headerBanner?.variant &&
    [HEADER_BANNER_VARIANT.GRADIENT, HEADER_BANNER_VARIANT.ACCENT].includes(
      headerBanner.variant as HeaderBannerVariant,
    )
      ? 'msq-btn-primary'
      : 'msq-btn-tertiary';

  const bgStyleClassName =
    {
      [HEADER_BANNER_VARIANT.GRADIENT]: 'bg-header-banner-gradient',
      [HEADER_BANNER_VARIANT.ACCENT]: 'bg-base-bg-secondary-pink',
    }[headerBanner?.variant as HeaderBannerVariant] || 'bg-base-bg-secondary';

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const containerEl = entry.target;
      const contentEl = contentRef.current;

      if (!contentEl) {
        setIsActive(false);
      } else {
        setAnimationDuration(contentEl.clientWidth / 35);
        setIsActive(contentEl.clientWidth > containerEl.clientWidth);
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={classNames('flex items-center', bgStyleClassName, className)}>
      <div className="v2-container max-xl-msq:px-2 flex justify-center items-center gap-2 xl-msq:gap-4">
        {headerBanner?.icon && (
          <ImageProxy
            alt=""
            aria-hidden="true"
            src={headerBanner.icon}
            width={48}
            height={48}
            className="flex-shrink-0 size-6 rounded"
          />
        )}

        <div
          ref={containerRef}
          className={classNames(
            'text-caption-sm-a flex max-xl-msq:flex-1 overflow-hidden whitespace-nowrap',
            {
              'mask-image-fade-x': isActive,
            },
          )}
        >
          <div
            style={{
              animationDuration: `${animationDuration}s`,
            }}
            className={classNames('flex gap-8', {
              'animate-slide-to-left-half': isActive,
            })}
          >
            <p ref={contentRef} dangerouslySetInnerHTML={{ __html: headerBanner?.text || '' }} />

            {isActive && (
              <p
                aria-hidden="true"
                className="mr-8"
                dangerouslySetInnerHTML={{ __html: headerBanner?.text || '' }}
              />
            )}
          </div>
        </div>

        {headerBanner?.button?.url && (
          <ExternalLink
            href={headerBanner.button.url}
            className={classNames(
              'msq-btn msq-btn-icon-sm md-msq:msq-btn-sm md-msq:min-w-[7.25rem] flex-shrink-0',
              buttonStyleClassName,
            )}
          >
            <span className="max-md-msq:hidden">{headerBanner.button?.text || 'Learn More'}</span>
            <ArrowRight className="size-3.5 md-msq:hidden" />
          </ExternalLink>
        )}
      </div>
    </div>
  );
};

export default HeaderBanner;
