import { PaginationSearchParams } from '@shared/api/types/common';

import Api, { ApiOptions } from './ApiProvider';
import { HeaderBannerData } from './types/headerBanner';

export const DEFAULT_GET_HEADER_BANNERS_LIMIT = 20;

export type GetHeaderBannersParams = PaginationSearchParams;

export const getHeaderBanners = async (
  { limit = DEFAULT_GET_HEADER_BANNERS_LIMIT, offset = 0 }: GetHeaderBannersParams = {},
  options: ApiOptions = {},
) => {
  return Api.get('v2/store/header-banner', {
    ...options,
    searchParams: {
      limit,
      offset,
    },
  }).json<HeaderBannerData[]>();
};
