import Link from 'next/link';

import { ComponentType, ReactNode, Suspense, useMemo } from 'react';

import classNames from 'classnames';

import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import { useHeaderBannerQuery } from '@shared/hooks/query/headerBanner';

import { Moon, Sun } from '@ui/uikit/components/icons/mono';
import { cn } from '@ui/uikit/utils';

import { BuySqrDesktopButton } from './BuySqrDesktopButton';
import { buySqrNavItems, useFeatureNavItems } from './constants';
import FeaturedNavDesktop from './FeaturedNavDesktop';
import FeaturedNavMobileBottomBar from './FeaturedNavMobileBottomBar';
import HeaderBanner from './HeaderBanner';
import NotificationsButton from './NotificationsButton';
import { PrimaryNavDesktop } from './PrimaryNavDesktop';
import { PrimaryNavMobileMenu } from './PrimaryNavMobileMenu';
import ProductsMenuButton from './ProductsMenuButton';
import { ProfileButton } from './ProfileButton';
import QuickNav from './QuickNav';
import HeaderSearchBar from './Search/HeaderSearchBar';
import MobileSearch from './Search/MobileSearch';
import { NavLayoutLinkBase, NavLayoutUser, PrimaryNavItem } from './types';

export interface NavLayoutProps {
  PrimaryNavLink?: ComponentType<{ href?: string; className?: string; children?: ReactNode }>;
  primaryNavItems?: PrimaryNavItem[];
  showMobileFeatureNav?: boolean;
  showKyc?: boolean;
  quickNavItems?: NavLayoutLinkBase[];
  primaryNavMenuTitle?: string;
  showThemeToggle?: boolean;
  children?: ReactNode;
  theme?: string;
  onThemeChange?: (theme: string) => void;
  className?: string;
  onLogin?: () => void;
  onLogout?: () => void;
  isLoading?: boolean;
  // TODO: remove when logic will be in uikit
  onBanxaBuyToken?: () => void;
  onSupport?: () => void;
  user?: NavLayoutUser;
  Logo: ComponentType<{ className?: string }>;
}

interface ContextValue {
  user?: NavLayoutUser;
  onLogin?: () => void;
  onBanxaBuyToken?: () => void;
  showKyc?: boolean;
}

const NavLayoutContext = createSafeContext<ContextValue>();

export const useNavLayout = () => useSafeContext(NavLayoutContext);

export const NavLayout = ({
  showThemeToggle,
  onThemeChange,
  theme,
  showMobileFeatureNav,
  children,
  primaryNavItems,
  className,
  isLoading,
  Logo,
  user,
  showKyc,
  primaryNavMenuTitle,
  quickNavItems,
  onLogin,
  onLogout,
  onBanxaBuyToken,
  onSupport,
}: NavLayoutProps) => {
  const isConnected = !isLoading && !!user;
  const featuredNavItems = useFeatureNavItems();
  const { data: headerBannerData } = useHeaderBannerQuery();

  const headerBanner = headerBannerData?.isActive ? headerBannerData : undefined;

  const value = useMemo(() => {
    return {
      user,
      onLogin,
      onBanxaBuyToken,
      showKyc,
    };
  }, [user, onLogin, onBanxaBuyToken, showKyc]);

  const hasQuickNav = !!quickNavItems?.length;

  const topNotificationHeight = headerBanner ? 'var(--nav-layout-header-banner-height)' : '0px';
  const headerHeight = hasQuickNav
    ? 'var(--nav-layout-header-height-with-quick-nav)'
    : 'var(--nav-layout-header-height-base)';
  const headerHeightWithTopNotification = `calc(${headerHeight} + ${topNotificationHeight})`;

  return (
    <NavLayoutContext.Provider value={value}>
      <div
        style={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          '--nav-layout-header-height': headerHeightWithTopNotification,
        }}
        className={classNames(
          'relative flex flex-col flex-1',
          {
            'max-xl-msq:pb-nav-layout-tab-menu': showMobileFeatureNav,
          },
          'pt-[var(--nav-layout-header-height)]',
          // to smoothly shift content to show banner
          'transition-[padding-top]',
          className,
        )}
      >
        <header
          className={classNames(
            'pr-[var(--removed-body-scroll-bar-size)] flex flex-col z-10 border-b border-b-base-border-light fixed left-0 top-0 w-full bg-header-bg backdrop-blur-[64px]',
            'h-[var(--nav-layout-header-height)]',
            // to smoothly increase height of the header to show banner
            'transition-[height]',
          )}
        >
          <HeaderBanner
            headerBanner={headerBanner}
            className={classNames(
              'overflow-hidden',
              // to smoothly show banner
              'transition-[height]',
              headerBanner ? 'h-[var(--nav-layout-header-banner-height)]' : 'h-0',
            )}
          />
          <div className={classNames('flex items-center flex-1')}>
            <div className="v2-container flex flex-col gap-2 md-msq:gap-3 justify-center">
              <div className="flex gap-1.5 md-msq:gap-3 items-center">
                <Link className="msq-btn" href="/">
                  {Logo && <Logo className="size-8" />}
                </Link>

                <PrimaryNavMobileMenu
                  onLogin={onLogin}
                  title={primaryNavMenuTitle}
                  isConnected={isConnected}
                  items={primaryNavItems}
                  className="3xl-msq:hidden"
                />

                <MobileSearch />

                {!!primaryNavItems?.length && (
                  <PrimaryNavDesktop
                    onLogin={onLogin}
                    isConnected={isConnected}
                    items={primaryNavItems}
                    className="max-3xl-msq:hidden z-10"
                  />
                )}

                <div className="flex gap-3 ml-auto">
                  <div className="flex">
                    {showThemeToggle && (
                      <button
                        onClick={() => onThemeChange?.(theme === 'dark' ? 'light' : 'dark')}
                        className="msq-btn msq-btn-icon-md msq-btn-ghost size-[2.25rem] text-base-text-primary md-msq:msq-btn-icon-lg"
                      >
                        {theme === 'dark' ? (
                          <Sun className="msq-btn-icon-child-md" />
                        ) : (
                          <Moon className="msq-btn-icon-child-md" />
                        )}
                      </button>
                    )}

                    {isConnected && <NotificationsButton />}
                  </div>

                  <div className="flex gap-3 ml-auto">
                    <div className="flex gap-1.5 max-md-msq:hidden">
                      <BuySqrDesktopButton items={buySqrNavItems} />

                      {/* {isConnected && (
                        <ActivityButtons />
                      )} */}
                    </div>

                    <div className="flex gap-1.5">
                      {!isConnected && (
                        <button
                          onClick={onLogin}
                          className={cn(
                            'msq-btn msq-btn-md !rounded-full min-h-[2.25rem] msq-btn-primary md-msq:msq-btn-lg max-md-msq:text-caption-sm-strong-m',
                            {
                              'msq-btn-loading': isLoading,
                            },
                          )}
                        >
                          Connect
                        </button>
                      )}

                      {isConnected && (
                        <ProfileButton user={user} onSupport={onSupport} onLogout={onLogout} />
                      )}

                      <ProductsMenuButton onLogin={onLogin} />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classNames('flex items-center xl-msq:gap-4', {
                  'max-xl-msq:hidden': !hasQuickNav,
                })}
              >
                {hasQuickNav && <QuickNav items={quickNavItems} className="w-full xl-msq:hidden" />}

                <FeaturedNavDesktop
                  user={user}
                  items={featuredNavItems}
                  className="max-xl-msq:hidden"
                />
                <Suspense
                  fallback={<div className="w-full max-xl-msq:hidden skeleton-block h-10"></div>}
                >
                  <HeaderSearchBar className="max-xl-msq:hidden w-full" />
                </Suspense>
              </div>
            </div>
          </div>
        </header>

        {children}

        {showMobileFeatureNav && (
          <FeaturedNavMobileBottomBar
            items={featuredNavItems}
            user={user}
            // NOTE: if you have to update hidden breakpoint we need to change next things too:
            // * WidgetContainer provider
            // * parent container padding
            className="fixed left-0 bottom-0 w-full z-10 pr-[var(--removed-body-scroll-bar-size)] xl-msq:hidden"
          />
        )}
      </div>
    </NavLayoutContext.Provider>
  );
};
