import { MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';

import { cn } from '@ui/uikit/utils';

import NavLayoutLink from './NavLayoutLink';
import { FeaturedNavItem, NavLayoutUser } from './types';

interface FeaturedNavDesktopProps {
  className?: string;
  items?: FeaturedNavItem[];
  user?: NavLayoutUser;
}

const FeaturedNavDesktop = ({ user: userFromProps, className, items }: FeaturedNavDesktopProps) => {
  const { data: user } = userFromProps || {};
  const { track } = useMixPanel();

  const handleCampaignsLinkClick = () => {
    if (user?.id) {
      track(MIXPANEL_EVENT_NAMES.CAMPAIGNS_CLICK, {
        distinct_id: user.id,
      });
    }
  };

  return (
    <nav className={cn('flex gap-2', className)}>
      {items?.map(({ iconClassName, id, href, isAuthRequired, Icon, label, isHidden }) => {
        const isCampaigns = id === 'campaigns';

        if (isHidden) {
          return null;
        }

        return (
          <NavLayoutLink
            key={id}
            href={href}
            isAuthRequired={isAuthRequired}
            onClick={() => isCampaigns && handleCampaignsLinkClick()}
            className="msq-btn msq-btn-lg msq-btn-secondary px-3 whitespace-nowrap flex-1"
          >
            <Icon className={cn('msq-btn-icon-child mr-1.5', iconClassName)} />
            {label}
          </NavLayoutLink>
        );
      })}
    </nav>
  );
};

export default FeaturedNavDesktop;
