import { Options } from 'ky';

import { getProcessEnvGlobalConfig, GLOBAL_ENV_CONFIG_FIELD } from '@shared/config/global';

import Api from './ApiProvider';

export const MIXPANEL_EVENT_NAMES = {
  ROOT_DOMAIN_PAGE_VIEW: 'Root Domain Page View',
  STORE_PAGE_VIEW: 'Store Page View',
  APPS_PAGE_VIEW: 'Apps Page View',
  GAMES_PAGE_VIEW: 'Games Page View',
  UPCOMING_PAGE_VIEW: 'Upcoming Page View',
  BANNER_CLICK: 'Banner Click',
  APPLICATION_CLICK: 'Application Click',
  APPLICATION_SEARCH: 'Application Search',
  SEE_ALL_CLICK: 'See All Click',
  CAMPAIGNS_CLICK: 'Campaigns Click',
  LAUNCHPAD_LINK_CLICK: 'Launchpad Link Click',
  LAUNCHPAD_WHITELIST_REQUIREMENTS_VIEWED: 'Launchpad Whitelist Requirements Viewed',
  STAKING_LINK_CLICK: 'Staking Link Click',
  OTC_LINK_CLICK: 'OTC Link Click',
  HOT_OFFERS_PAGE_VIEW: 'Hot Offers Page View',
  HOT_OFFER_VIEW_BUTTON_CLICK: 'Hot Offer View Button Click',
  HOT_OFFER_CLAIM_BUTTON_CLICK: 'Hot Offer Claim Button Click',
  HOT_OFFER_FULFILLED: 'Hot Offer Fulfilled',
  SWAP_PAGE_VIEW: 'Swap Page View',
  SWAP_GIVE_PERMISSION_BUTTON_CLICK: 'Swap Give Permission Button Click',
  SWAP_BUTTON_CLICK: 'Swap Button Click',
  SWAP_CONFIRMATION: 'Swap Confirmation',
  VALIDATION_APP_PAGE_CLICK: 'Validation App Page Click',
  VALIDATION_USER_PROFILE_CLICK: 'Validation User Profile Click',
} as const;

export type MixpanelEventName = (typeof MIXPANEL_EVENT_NAMES)[keyof typeof MIXPANEL_EVENT_NAMES];

export const MIXPANEL_PAGE = {
  HOME: 'Home',
  APPS: 'Apps',
  GAMES: 'Games',
  UPCOMING: 'Upcoming',
  HOT_OFFERS: 'Hot Offers',
} as const;

export type MixpanelPage = string | (typeof MIXPANEL_PAGE)[keyof typeof MIXPANEL_PAGE];

const basePath = getProcessEnvGlobalConfig(GLOBAL_ENV_CONFIG_FIELD.BASE_PATH);

export const getMixPanelPage = (): MixpanelPage => {
  let pathname = '';

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname;
  } else {
    pathname = basePath + '/';
  }

  if (pathname === basePath + '/') {
    return MIXPANEL_PAGE.HOME;
  }

  if (pathname.startsWith(basePath + '/apps')) {
    return MIXPANEL_PAGE.APPS;
  }

  if (pathname.startsWith(basePath + '/games')) {
    return MIXPANEL_PAGE.GAMES;
  }

  if (pathname.startsWith(basePath + '/upcoming')) {
    return MIXPANEL_PAGE.UPCOMING;
  }

  if (pathname.startsWith(basePath + '/hot-offers')) {
    return MIXPANEL_PAGE.HOT_OFFERS;
  }

  return `Not configured page: ${pathname}`;
};

export interface MixpanelEvent {
  [MIXPANEL_EVENT_NAMES.ROOT_DOMAIN_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.STORE_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.APPS_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.GAMES_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.UPCOMING_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.BANNER_CLICK]: {
    distinct_id: number;
    page: MixpanelPage;
    banner: string;
  };
  [MIXPANEL_EVENT_NAMES.APPLICATION_CLICK]: {
    distinct_id: number;
    page: MixpanelPage;
    application: string;
  };
  [MIXPANEL_EVENT_NAMES.APPLICATION_SEARCH]: {
    distinct_id: number;
    page: MixpanelPage;
    search: string;
  };
  [MIXPANEL_EVENT_NAMES.SEE_ALL_CLICK]: {
    distinct_id: number;
    page: MixpanelPage;
    group: string;
  };
  [MIXPANEL_EVENT_NAMES.CAMPAIGNS_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.LAUNCHPAD_LINK_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.LAUNCHPAD_WHITELIST_REQUIREMENTS_VIEWED]: {
    distinct_id: number;
    project: string;
  };
  [MIXPANEL_EVENT_NAMES.STAKING_LINK_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.OTC_LINK_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.HOT_OFFERS_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.HOT_OFFER_VIEW_BUTTON_CLICK]: {
    distinct_id: number;
    offer: string;
  };
  [MIXPANEL_EVENT_NAMES.HOT_OFFER_CLAIM_BUTTON_CLICK]: {
    distinct_id: number;
    offer: string;
  };
  [MIXPANEL_EVENT_NAMES.HOT_OFFER_FULFILLED]: {
    distinct_id: number;
    offer: string;
  };
  [MIXPANEL_EVENT_NAMES.SWAP_PAGE_VIEW]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.SWAP_GIVE_PERMISSION_BUTTON_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.SWAP_BUTTON_CLICK]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.SWAP_CONFIRMATION]: {
    distinct_id: number;
  };
  [MIXPANEL_EVENT_NAMES.VALIDATION_APP_PAGE_CLICK]: {
    distinct_id: number;
    application: string;
  };
  [MIXPANEL_EVENT_NAMES.VALIDATION_USER_PROFILE_CLICK]: {
    distinct_id: number;
    application: string;
  };
}

export type NormalizedMixpanelToggles = Record<MixpanelEventName, boolean>;

interface MixpanelToggle {
  event_name: MixpanelEventName;
  enabled: boolean;
  created_at: string;
  updated_at: string;
}

export const getMixpanelToggles = async (options?: Options) => {
  return Api.get('v2/trace/business-events/settings', {
    ...options,
  }).json<MixpanelToggle[]>();
};

export const normalizeMixpanelToggles = (data: MixpanelToggle[]): NormalizedMixpanelToggles => {
  return data?.reduce<NormalizedMixpanelToggles>((acc, row) => {
    return {
      ...acc,
      [row.event_name]: row.enabled,
    };
  }, {} as NormalizedMixpanelToggles);
};

export const getNormalizedMixpanelToggles = async (options?: Options) => {
  const data = await getMixpanelToggles(options);

  return normalizeMixpanelToggles(data);
};
