import React from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';

import { BUY_SQR_BANXA_ITEM } from './constants';
import { useNavLayout } from './Header';
import NavLayoutLink from './NavLayoutLink';
import { BuySqrNavItems } from './types';

export interface BuySqrDialogProps extends Dialog.DialogProps {
  items?: BuySqrNavItems;
}

const BuySqrDialog = ({ items, onOpenChange, ...props }: BuySqrDialogProps) => {
  const { onBanxaBuyToken } = useNavLayout();

  return (
    <Dialog.Root onOpenChange={onOpenChange} {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-animate msq-dialog-mobile-menu-m"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className="z-20 msq-dialog-content sm-msq:msq-dialog-content-right sm-msq:msq-dialog-content-sm"
          >
            <div className="msq-dialog-header">
              <Dialog.Title className="msq-dialog-title">Buy & Sell SQR</Dialog.Title>

              <Dialog.Close asChild>
                <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                  <CrossLg className="msq-btn msq-btn-icon-child" />
                </button>
              </Dialog.Close>
            </div>

            <div className="msq-dialog-body p-2 overflow-x-hidden">
              <ul>
                {items?.map((item) => {
                  if ('items' in item) {
                    return (
                      <li key={item.id}>
                        <h3 className="msq-sub-menu-title msq-sub-menu-title-mobile">
                          {item.title}
                        </h3>
                        <ul>
                          {item.items.map((subItem) => {
                            const Icon = subItem.Icon;

                            return (
                              <li key={subItem.id}>
                                {subItem.id === BUY_SQR_BANXA_ITEM.id ? (
                                  <button
                                    onClick={() => {
                                      onBanxaBuyToken?.();
                                      onOpenChange?.(false);
                                    }}
                                    className="msq-sub-menu-link msq-sub-menu-link-mobile w-full"
                                  >
                                    <Icon className="size-msq-sub-menu-icon" />
                                    {subItem.label}
                                  </button>
                                ) : (
                                  <NavLayoutLink
                                    onClick={() => {
                                      onOpenChange?.(false);
                                    }}
                                    className="msq-sub-menu-link msq-sub-menu-link-mobile"
                                    href={subItem.href}
                                    target="_blank"
                                  >
                                    <Icon className="size-msq-sub-menu-icon" />
                                    {subItem.label}
                                  </NavLayoutLink>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  }

                  const { Icon } = item;

                  return (
                    <li key={item.id}>
                      <NavLayoutLink
                        onClick={() => {
                          onOpenChange?.(false);
                        }}
                        href={item.href}
                        className="msq-sub-menu-link msq-sub-menu-link-mobile"
                      >
                        <Icon className="size-msq-sub-menu-icon" />
                        {item.label}
                      </NavLayoutLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default BuySqrDialog;
