import React, { ComponentPropsWithoutRef, Fragment } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cn } from '@ui/uikit/utils';

import { BuySqrButton } from './BuySqrButton';
import { BUY_SQR_BANXA_ITEM } from './constants';
import { useNavLayout } from './Header';
import NavLayoutLink from './NavLayoutLink';
import { BuySqrNavItems } from './types';

export interface BuySqrDesktopButtonProps extends ComponentPropsWithoutRef<'button'> {
  items?: BuySqrNavItems;
}

export const BuySqrDesktopButton = ({ className, items }: BuySqrDesktopButtonProps) => {
  const { onBanxaBuyToken } = useNavLayout();

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <BuySqrButton className={cn('msq-btn-lg min-w-[8.25rem]', className)} />
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="start"
          className="msq-sub-menu mt-1 min-w-[13.5rem] max-h-[calc(100vh-4.5rem)] overflow-y-auto data-[state=open]:animate-show-menu data-[state=closed]:animate-hide-menu z-20"
        >
          {items?.map((item) => {
            if ('items' in item) {
              return (
                <Fragment key={item.id}>
                  <DropdownMenu.Label className="msq-sub-menu-title msq-sub-menu-title-desktop">
                    {item.title}
                  </DropdownMenu.Label>

                  {item.items.map((subItem) => {
                    const { Icon } = subItem;

                    return (
                      <DropdownMenu.Item key={subItem.id} asChild>
                        {subItem.id === BUY_SQR_BANXA_ITEM.id ? (
                          <button
                            onClick={onBanxaBuyToken}
                            className="msq-sub-menu-link w-full msq-sub-menu-link-desktop"
                          >
                            <Icon className="size-msq-sub-menu-icon" />
                            {subItem.label}
                          </button>
                        ) : (
                          <NavLayoutLink
                            href={subItem.href}
                            target="_blank"
                            className="msq-sub-menu-link msq-sub-menu-link-desktop"
                          >
                            <Icon className="size-msq-sub-menu-icon" />
                            {subItem.label}
                          </NavLayoutLink>
                        )}
                      </DropdownMenu.Item>
                    );
                  })}
                </Fragment>
              );
            }

            const { Icon } = item;

            return (
              <DropdownMenu.Item key={item.id} asChild>
                <NavLayoutLink
                  href={item.href}
                  className="msq-sub-menu-link msq-sub-menu-link-desktop"
                >
                  <Icon className="size-msq-sub-menu-icon" />
                  {item.label}
                </NavLayoutLink>
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
  //   <NavigationMenu.Root className={cn("w-full z-10", className)}>
  //     <NavigationMenu.List>
  //       <NavigationMenu.Item>
  //         <NavigationMenu.Trigger asChild>
  //           <BuySqrButton className='msq-btn-lg' />
  //         </NavigationMenu.Trigger>

  //         <NavigationMenu.Content
  //           className={cn(
  //             'msq-sub-menu absolute mt-1 top-full min-w-[13.5rem] duration-150',
  //             'data-[motion=from-start]:animate-slide-in-from-left data-[motion=from-end]:animate-slide-in-from-right data-[motion=to-start]:animate-slide-out-to-left data-[motion=to-end]:animate-slide-out-to-right',
  //           )}
  //         >
  //           <ul>
  //             {items?.map((item) => {
  //               if ('items' in item) {
  //                 return (
  //                   <li key={item.id}>
  //                     <h3 className="msq-sub-menu-title msq-sub-menu-title-desktop">{item.title}</h3>
  //                     <ul>
  //                       {item.items.map((subItem) => {
  //                         const Icon = subItem.Icon;

  //                         return (
  //                           <li key={subItem.id}>
  //                             <a className="msq-sub-menu-link msq-sub-menu-link-desktop" href={subItem.href}>
  //                               <Icon className="size-msq-sub-menu-icon" />
  //                               {subItem.label}
  //                             </a>
  //                           </li>
  //                         );
  //                       })}
  //                     </ul>
  //                   </li>
  //                 );
  //               }

  //               const { Icon } = item;

  //               return (
  //                 <li key={item.id}>
  //                   <a href={item.href} className="msq-sub-menu-link msq-sub-menu-link-desktop">
  //                     <Icon className="size-msq-sub-menu-icon" />
  //                     {item.label}
  //                   </a>
  //                 </li>
  //               );
  //             })}
  //           </ul>
  //         </NavigationMenu.Content>
  //       </NavigationMenu.Item>
  //     </NavigationMenu.List>
  //   </NavigationMenu.Root>
  // );
};
