import { usePathname } from 'next/navigation';

import React from 'react';

import classNames from 'classnames';

import { MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';

import { cn } from '@ui/uikit/utils';

import NavLayoutLink from './NavLayoutLink';
import { FeaturedNavItem, NavLayoutUser } from './types';

interface FeaturedNavMobileBottomBarProps {
  className?: string;
  items?: FeaturedNavItem[];
  user?: NavLayoutUser;
}

const FeaturedNavMobileBottomBar = ({
  user: userFromProps,
  className,
  items,
}: FeaturedNavMobileBottomBarProps) => {
  const pathname = usePathname();
  const { data: user } = userFromProps || {};
  const { track } = useMixPanel();

  const handleCampaignsLinkClick = () => {
    if (user?.id) {
      track(MIXPANEL_EVENT_NAMES.CAMPAIGNS_CLICK, {
        distinct_id: user.id,
      });
    }
  };

  return (
    <nav
      className={cn(
        'flex h-nav-layout-tab-menu bg-tab-menu-bg backdrop-blur-[64px] border-t border-tab-menu-border',
        className,
      )}
    >
      {items?.map(
        ({ id, iconClassName, isAuthRequired, href, Icon, shortLabel, isHidden, label }) => {
          const isCampaigns = id === 'campaigns';

          if (isHidden) {
            return null;
          }

          const isActive = pathname === href;

          return (
            <NavLayoutLink
              key={id}
              href={href}
              isAuthRequired={isAuthRequired}
              onClick={() => isCampaigns && handleCampaignsLinkClick()}
              className={classNames('msq-btn text-caption-xs-m flex-col h-full w-full gap-1', {
                'text-tab-menu-tab-active-text': isActive,
                'text-tab-menu-tab-default-text': !isActive,
              })}
            >
              <Icon className={cn('size-5', iconClassName)} />
              {shortLabel || label}
            </NavLayoutLink>
          );
        },
      )}
    </nav>
  );
};

export default FeaturedNavMobileBottomBar;
